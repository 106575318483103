<template>
  <div class="project-form-content">
    <!-- Project Details -->
    <fd-form-section title="Project Details">
      <div class="row col-12 mb-2">
        <fd-select
          v-if="type === 'agency'"
          v-model="project.developerId"
          class="col-12 sm-col-6 md-col-3 px-1 mb-2"
          label="Developer"
          selectText="Select a developer"
          :options="developerOptions"
          :validators="[validator.required]"
          :isLoading="isDeveloperLoading"
        >
        </fd-select>
        <fd-input
          v-model="project.name"
          class="col-12 sm-col-6 md-col-3 px-1 mb-2"
          label="Project Name"
          name="projectName"
          type="text"
          :validators="[validator.required]"
        >
        </fd-input>
        <fd-input
          v-model="project.referenceCode"
          class="col-12 sm-col-6 md-col-3 px-1 mb-2"
          label="Reference Code Initital"
          name="referenceCodeInitial"
          type="text"
          :validators="[validator.required]"
        >
        </fd-input>
        <fd-select
          v-model="project.projectType"
          class="col-12 sm-col-6 md-col-3 px-1 mb-2"
          label="Project Type"
          selectText="Select a project type"
          :options="projectTypeOptions"
          :validators="[validator.required]"
        >
        </fd-select>
        <fd-input
          v-model="project.completionYear"
          class="col-12 sm-col-6 md-col-3 px-1 mb-2"
          label="Completion Year"
          type="text"
          :validators="[validator.year]"
        >
        </fd-input>
      </div>
      <div class="row col-12">
        <fd-multi-select
          v-model="project.tenureType"
          class="col-12 sm-col-6 md-col-3 px-1 mb-2"
          label="Tenure Type"
          name="tenureType"
          selectText="Select tenure type"
          :options="tenureTypeOptions"
          :validators="[validator.required]"
        ></fd-multi-select>
        <fd-select
          v-model="project.furnishingType"
          class="col-12 sm-col-6 md-col-3 px-1 mb-2"
          label="Furnishing Type"
          selectText="Select a furnishing type"
          :options="furnishingTypeOptions"
          :validators="[validator.required]"
        >
        </fd-select>
        <fd-select
          v-model="project.propertyCategory"
          class="col-12 sm-col-6 md-col-3 px-1 mb-2"
          label="Property Category"
          selectText="Select a property category"
          :options="propertyCategoryOptions"
          :validators="[validator.required]"
        >
        </fd-select>
        <fd-select
          v-model="project.status"
          class="col-12 sm-col-6 md-col-3 px-1 mb-2"
          label="Status"
          selectText="Select a status"
          :options="statusOptions"
          :validators="[validator.required]"
        >
        </fd-select>
      </div>
    </fd-form-section>

    <fd-form-section title="Medias">
      <!-- main photo -->
      <div class="col-12 px-1 mb-2 main-photo">
        <image-uploader
          v-model="project.mainPhoto"
          :multiple="false"
          label="Main Photo"
          required
          watermark
          @error="
            (error, imageName) => {
              $reportError(error, 'Upload mainPhoto (Edit Project)');
            }
          "
        >
        </image-uploader>
      </div>
      <!-- sitemap -->
      <div class="col-12 px-1 mb-2 sitemaps">
        <image-uploader
          v-model="project.siteMap"
          multiple
          label="Site Plans"
          required
          watermark
          @error="
            (error, imageName) => {
              $reportError(error, 'Upload siteMap (Edit Project)');
            }
          "
        >
        </image-uploader>
      </div>
      <!-- gallery -->
      <div class="col-12 px-1 mb-2 gallery">
        <image-uploader
          v-model="project.gallery"
          multiple
          label="Gallery Photos"
          watermark
          @error="
            (error, imageName) => {
              $reportError(error, 'Upload gallery (Edit Project)');
            }
          "
        >
        </image-uploader>
      </div>
      <!-- videolink -->
      <fd-input
        v-model="project.videoLink"
        class="col-12 sm-col-6 md-col-3 px-1 mb-2"
        label="Video Link"
        name="projectVideoLink"
        type="url"
        placeholder="https://"
        :validators="[validator.website]"
      >
      </fd-input>
      <!-- saleform -->
      <file-uploader
        v-model="project.saleForm"
        class="col-12 px-1 mb-2"
        label="Sale Form Upload"
        :maxSize="5"
        :allowed="['pdf', 'jpg', 'png', 'jpeg']"
        accept=".pdf, image/jpeg, image/png"
        @isLoading="
          (val) => {
            setIsLoading(isLoading);
          }
        "
      ></file-uploader>
    </fd-form-section>

    <!-- Address -->
    <fd-form-section title="Address Details">
      <addresses
        v-if="isEdit ? project.country : true"
        :isEdit="isEdit"
        :country.sync="project.country"
        :state.sync="project.state"
        :city.sync="project.city"
        :area.sync="project.area"
        :building.sync="project.building"
        :lat.sync="project.latitude"
        :lng.sync="project.longitude"
        class="row col-12"
        showBuilding
        showGeocode
        geocodeRequired
        @change="
          (data) => {
            project.countryId = data.country.id;
            project.stateId = data.state.id;
            project.cityId = data.city.id;
            project.areaId = data.area.id;
            project.buildingId = data.building.id;
          }
        "
      ></addresses>
    </fd-form-section>

    <fd-form-section title="Additional Info">
      <!-- Introduction -->
      <h6 class="mb-1">Introduction</h6>
      <basic-tip-tap
        class="mb-2"
        v-model="project.introduction"
      ></basic-tip-tap>
      <fd-multi-select
        v-model="project.projectFacilities"
        class="col-12 sm-col-6 px-1 mb-2"
        label="Facilities"
        selectText="Select facilities"
        :options="facilitiesOptions"
        :optionValueKey="`id`"
        :optionLabelKey="`name`"
        :isLoading="isFacilitiesLoading"
      ></fd-multi-select>
      <fd-multi-select
        v-model="project.projectAmenities"
        class="col-12 sm-col-6 px-1 mb-2"
        label="Amenities"
        selectText="Select amenities"
        :options="amenitiesOptions"
        :optionValueKey="`id`"
        :optionLabelKey="`name`"
        :isLoading="isAmenitiesLoading"
      >
      </fd-multi-select>
      <!-- Project Tags -->
      <fd-tags-input
        v-model="project.projectTags"
        class="col-12 sm-col-6 px-1 mb-2"
        label="Project Tags"
        :transformText="(text) => text.toLowerCase()"
        :isDuplicate="
          (tags, tag) =>
            tags
              .map((t) => t.text.toLowerCase())
              .indexOf(tag.text.toLowerCase()) !== -1
        "
        :autocompleteItems="projectTagOptions"
        :tagValidation="projectTagValidations"
        :separators="[',', ';', ' ']"
        @change="
          (tags) => {
            project.hashtag = tags.map((tag) => tag.text).join(',');
          }
        "
      ></fd-tags-input>
      <!-- lon -->
      <!-- lat -->
    </fd-form-section>
  </div>
</template>

<script>
import {
  tenureType,
  projectType,
  furnishingType,
  propertyCategory,
  projectStatus,
  areaUnitType
} from "@/enums";

import {
  required,
  website,
  price,
  year
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

import ManagerRoles from "@/modules/Project/classes/ManagerRoles";

import ProjectModel from "@/modules/Project/models/ProjectModel";
import { jsonToArray } from "@/utils/array";

export default {
  components: {
    FdMultiSelect: () =>
      import("@/components/GlobalComponents/FormComponents/FdMultiSelect"),
    ImageUploader: () => import("@/components/GlobalComponents/ImageUploader"),
    FileUploader: () => import("@/components/GlobalComponents/FileUploader"),
    BasicTipTap: () => import("@/components/GlobalComponents/BasicTipTap"),
    Addresses: () => import("@/components/GlobalComponents/Address")
  },
  mixins: [],
  props: {
    type: {
      type: String,
      default: "developer",
      validator: (val) => new ManagerRoles().getRoles().includes(val)
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    getProjectAPI: {
      type: Function
    },
    getFacilitiesAPI: {
      type: Function
    },
    getAmenitiesAPI: {
      type: Function
    },
    getDevelopersAPI: {
      type: Function
    },
    getPropertyTagsAPI: {
      type: Function
    }
  },
  data: function () {
    return {
      // Main Data
      project: {
        tenureType: [],
        projectType: "",

        mainPhoto: [],
        siteMap: [],
        gallery: [],

        latitude: 0,
        longitude: 0,

        projectFacilities: [],
        projectAmenities: [],
        projectTags: [],
        hashtag: ""
      },

      // Select Options
      isDeveloperLoading: false,
      developerOptions: [],

      isFacilitiesLoading: false,
      facilitiesOptions: [],
      isAmenitiesLoading: false,
      amenitiesOptions: [],

      projectTagOptions: [],
      tenureTypeOptions: jsonToArray(tenureType, (e) => {
        return e;
      }),
      projectTypeOptions: jsonToArray(projectType, (e) => {
        return {
          id: e,
          name: e
        };
      }),
      furnishingTypeOptions: jsonToArray(furnishingType, (e) => {
        return {
          id: e,
          name: e
        };
      }),
      propertyCategoryOptions: jsonToArray(propertyCategory, (e) => {
        return {
          id: e,
          name: e
        };
      }),
      statusOptions: jsonToArray(projectStatus, (e) => {
        return {
          id: e,
          name: e
        };
      }),
      areaUnitOptions: jsonToArray(areaUnitType, (e) => {
        return {
          id: e,
          name: e
        };
      }),

      // Validator
      projectTagValidations: [
        {
          classes: "alphanumeric",
          rule: /^[a-z0-9]+$/i,
          disableAdd: true
        }
      ],

      validator: {
        required,
        website,
        price,
        year
      }
    };
  },
  computed: {},
  watch: {
    project: {
      deep: true,
      handler(val) {
        this.$emit("update:formData", val);
      }
    }
  },
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      this.setIsLoading(true);
      if (this.isEdit) {
        await this.getProjectData();
      }
      if (this.type === ManagerRoles.AGENCY) {
        await this.getDevelopers();
      }
      await Promise.all([
        this.getFacilities(),
        this.getAmenities(),
        this.getTags()
      ]);
      this.setIsLoading(false);
    },
    setIsLoading(bool) {
      this.$store.commit("setIsLoading", bool);
      this.$emit("update:isLoading", bool);
    },
    // ============================== API Related ==============================
    async getProjectData() {
      try {
        let data = await this.getProjectAPI(this.$route.params.id);
        this.project = new ProjectModel(data, {
          isDeveloper: this.type === "developer",
          isAgency: this.type === "agency"
        }).fromResponseToEdit();
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        throw error;
      }
    },
    async getDevelopers() {
      try {
        this.isDeveloperLoading = true;
        let data = await this.getDevelopersAPI();
        this.developerOptions = data.data;
        this.isDeveloperLoading = false;
      } catch (error) {
        this.isDeveloperLoading = false;
        throw error;
      }
    },
    async getFacilities() {
      try {
        this.isFacilitiesLoading = true;
        let data = await this.getFacilitiesAPI();
        this.facilitiesOptions = this._.cloneDeep(data);
        this.isFacilitiesLoading = false;
      } catch (error) {
        this.isFacilitiesLoading = false;
        throw error;
      }
    },
    async getAmenities() {
      try {
        this.isAmenitiesLoading = true;
        let data = await this.getAmenitiesAPI();

        this.amenitiesOptions = this._.cloneDeep(data);
        this.isAmenitiesLoading = false;
      } catch (error) {
        this.isAmenitiesLoading = false;
        throw error;
      }
    },
    async getTags() {
      try {
        this.setIsLoading(true);
        let data = await this.getPropertyTagsAPI();
        let mappedData = data.map((tag) => ({ text: tag.name }));
        this.projectTagOptions = this._.cloneDeep(mappedData);
        this.setIsLoading(false);
      } catch (error) {
        this.setIsLoading(false);
        this.projectTagOptions = [];
        throw error;
      }
    }
  }
};
</script>

<style lang="scss"></style>
